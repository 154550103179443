import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)
let originPush=VueRouter.prototype.push;
VueRouter.prototype.push=function(location,resolve,reject){
  if(resolve&&resolve){
    originPush.call(this,location,resolve,reject)
  }else{
    originPush.call(this,location,()=>{},()=>{})
  }
}
const routes = [
    {
        path: '*',
        redirect: '/index'
    },
    {
        path: '/goConnect',
        name: 'goConnect',
        component: () => import( '../views/goConnect.vue')//重定向首页
    },
    {
      path: '/index',
      name: 'index',
      component: () => import( '../views/carousel.vue')//首页
    },
    {
      path: '/detail',
      name: 'detail',
      component: () => import( '../views/detail.vue')//产品细节
    },
    {
      path: '/caseShow',
      name: 'caseShow',
      component: () => import( '../views/caseShow.vue')//案例展示
    }

]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { y:0 }
  },
})

export default router
