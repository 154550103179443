<template>
  <!-- 导航栏 -->
  <div class="topNav">
    <!-- 版心 -->
    <div class="content">
      <!-- 导航栏左边 -->
      <div class="Navleft ">
        <!-- <span class="right"> 珠海市众意达科技有限公司</span> -->
        <img :src="`https://zydadmin.sdsj88.cn/${list[9].value}`" alt=""
          style="width: 163px;height: 64px;margin-top: 5px; cursor: pointer;" @click="goIndex">
      </div>
      <!-- 导航栏右边 -->
      <div class="Navright" style="font-size: 15px; cursor: pointer;">
        <div v-for="(item, index) in menuList" :key="index">
          <div class="NavIndex" v-if="!item.children || item.children.length == 0" @click="navigateTo(item)">{{
            item.name }}</div>
          <el-dropdown class=" left" placement="bottom" style="cursor: pointer;"
            v-if="item.children && item.children.length > 0">
            <span class="el-dropdown-link">
              产品<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" style="margin-top: 25px; background-color: #fafcff;">
              <a v-for="(dropdownItem, navListindex) in item.children" :key="dropdownItem.id"
                @click="goDetail(dropdownItem)" style="text-decoration: none;"><el-dropdown-item
                  style="color: black;">{{
                    dropdownItem.name }}
                </el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="right" @click="goConnect">联系合作</div>
      </div>
    </div>
  </div>
</template>

<script>
import { menuList } from '@/api/index'
export default {
  name: 'topNav',
  data() {
    return {
      menuList: [],
    }
  },
  props:['list'],
  mounted() {
    menuList().then(res => {
      console.log(res)
      this.menuList = res.data.lists
    })
  },
  methods: {
    // 点击联系合作
    goConnect() {
      console.log('去联系合作');
      if (this.$route.path === '/goConnect') {
        return
      } else if (this.$route.path === '/index' || this.$route.path === '/detail') {
        this.$router.push({ path: '/goConnect' })
      }
    },
    //点击去首页
    goIndex() {
      console.log('去首页');
      if (this.$route.path === '/index') {
        return
      } else if (this.$route.path === '/goConnect' || this.$route.path === '/detail' || this.$route.path === '/caseShow') {
        this.$router.push({ path: '/index' })
      }
    },
    navigateTo(e) {
      console.log("🚀 ~ navigateTo ~ e:", e)
      if(e.id==4){
        this.$router.push({ path: '/index' })
      }else{
        console.log(this.$route.path);
      this.$router.push({
        path: `/detail?id=${e.id}`,
      })
      }
     
    },
    goDetail(navListItem) {
      console.log('去详情页', navListItem);
      this.$router.push({
        path: `/detail?id=${navListItem.id}`,
      })
    }
  }
}
</script>
<style scoped lang="scss">
// 导航栏
.topNav {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 70px;
  // border: 1px solid red;
  background: white;

  // box-shadow: 0px 1px 1px rgba(155, 153, 153, 0.5);
  // backdrop-filter: blur(1px);
  //版心
  .content {
    margin: 0 auto;
    width: 1200px;
    height: 100%;
    // border: 1px solid red;
    display: flex;
    justify-content: space-between;

  }

  //导航栏左边
  .Navleft {
    width: 450px;
    height: 70px;
    // border: 1px solid red;
    // line-height:70px;
    // font-size: 20px;
    font-weight: bold;
    color: #f56027;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .left {
      font-size: 20px;
      color: black;

    }

    .right {
      font-size: 20px;
      color: #f56027;
      cursor: pointer;
    }
  }

  //导航栏右边
  .Navright {
    width: 562px;
    height: 100%;
    // border: 1px solid red;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .el-dropdown-link {
      cursor: pointer;
      // color: #409EFF;
    }

    .el-dropdown-link:hover {
      cursor: pointer;
      color: #f56027;
    }

    .el-icon-arrow-down {
      font-size: 12px;
    }

    .left {
      font-size: 15px;
      text-align: center;
      // margin-top: 23px
    }

    .NavIndex:hover {
      color: #f56027;
    }

    .NavAboutus:hover {
      color: #f56027;
    }

    .NavNews:hover {
      color: #f56027;
    }

    .NavCase a:hover {
      color: #f56027;
    }

    .right {
      width: 143px;
      height: 45px;
      border: 1px solid #f56027;
      border-radius: 5px;
      font-size: 20px;
      text-align: center;
      line-height: 45px;
      color: #f56027;
      cursor: pointer;
    }

    .right:hover {
      background-color: #f3e4e1;
    }
  }
}
</style>
