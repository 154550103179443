<template>
  <div id="app">
    <!-- 顶部导航 -->
    <topNav :list="list"/>
    <!-- 路由 -->
    <router-view/>
    <!-- 底部 -->
    <bottom :list="list"/>
  </div>
</template>
<script>
import topNav from '@/components/topNav.vue'
import bottom from '@/components/bottom.vue'
import {TopBottomInfo} from '@/api/index'
export default {
  name: 'App',
  components: {
    topNav,
    bottom
  },
  data () {
    return {
      list:[],
    }
  },
  methods: {
  },
  mounted () {
    TopBottomInfo().then(res=>{
      console.log(res);
      if(res.code==1){
        this.list=res.data.lists
      }
    })
  },
  watch: {
  }
}
</script>
<style lang="scss">
 .el-dropdown-menu__item:hover {
    background-color: #ffffff !important;
    font-style: italic;
    color: #f56027 !important;
}
.el-carousel__indicators--outside button{
  background-color: #f56027 !important;
}
.el-textarea__inner{
  height: 100px;
}

</style>
